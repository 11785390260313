<template>
  <lab-task> <lab-redox-titration :conc-oxalic-acid="concOxalicAcid" /></lab-task>
</template>

<script>
import LabRedoxTitration from '../simulations/LabRedoxTitration';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';
import LabTask from './labs/LabTask';

export default defineComponent({
  name: 'RedoxTitrationSIM',
  components: {LabRedoxTitration, LabTask},
  mixins: [DynamicQuestionMixin()],
  computed: {
    concOxalicAcid() {
      return this.taskState.getValueBySymbol('concOxalicAcid').content.toFloat();
    },
  },
});
</script>
